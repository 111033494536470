@keyframes slideInFromRight {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(0);
    }
  }
  
  .slide-in-box {
    animation: slideInFromRight 0.7s ease-in-out;
  }

  html,body {
    margin:0;
    padding:0;
}