
@media (max-width: 575px) {
    .custom-reactour {
        max-width: 100% !important;
    }
}

@media (min-width: 576px) and (max-width: 767px) {
    .custom-reactour {
        max-width: 80% !important;
    }
  }
  
  @media (min-width: 768px) and (max-width: 991px) {
    .custom-reactour {
        max-width: 70% !important;
    }
  }
  
  @media (min-width: 992px) and (max-width: 1199px) {
    .custom-reactour {
        max-width: 60% !important;
    }
  }
  
  @media (min-width: 1200px) {
    .custom-reactour {
        max-width: 50% !important;
    }
  }
  